import React, { useEffect } from 'react';
import tickCircleSolidWhite from 'assets/images/button/tick-circle-solid-white.svg';
import whiteCloseButton from 'assets/images/icons/white-close-button.svg';
import styles from './Toast.module.scss';

export const ToastType = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
};

const Toast = ({ msg, closeHandler, type = ToastType.INFO }) => {
  let toastTypeClass = '';
  if (type === ToastType.SUCCESS) {
    toastTypeClass = styles.success;
  } else if (type === ToastType.ERROR) {
    toastTypeClass = styles.error;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={`${styles.toast} ${toastTypeClass}`} role="alert">
      <img src={tickCircleSolidWhite} alt="checkmark" />{msg}
      <button type="button" onClick={closeHandler}><img src={whiteCloseButton} alt="close button" /></button>
    </div>
  );
};

export default Toast;
