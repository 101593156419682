import React from 'react';
import styles from './PostDatedChangeInfo.module.scss';

const PostDatedChangeInfo = () => (
  <div className={styles.wrapper}>
    <p className={styles.title}>Your renewal&apos;s processing</p>
    <p>
      If you&apos;ve changed the vehicle on your policy,
      you&apos;ll see this update on your renewal date.
      But don&apos;t worry, you&apos;re still covered
      in your new vehicle if you break down before then.
    </p>
  </div>
);

export default PostDatedChangeInfo;
