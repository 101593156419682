import React, { useState, useEffect } from 'react';
import ReactHtmlParser from '@orrisroot/react-html-parser';
import { connect } from 'react-redux';
import {
  format, getDate, getMonth, getYear,
} from 'date-fns';

import * as VehicleActions from 'actions/vehiclesInfoActions';
import ComponentView from 'common/ComponentView';
import ToggleControl from 'common/ToggleControl';
import { DropdownDayMonthPicker, isValidDate } from 'components/DropdownDayMonthPicker';
import { generalMessages } from 'constants/cms/messageConstants';
import {
  fromJS, List, Map, updateIn,
} from 'immutable';
import trackEvent from 'services/AnalyticsService';
import { getMessage } from 'services/MessageService';
import { ResponseCode } from 'services/models/httpResponse';
import { UpdateVehicleReminders, GetMotInfo } from 'services/VehicleService';
import { loggerFunc } from 'utils/logger';
import { getReminderText } from 'utils/vehicleHelpers';
import { addAttributesToNode } from 'utils/ContentHelpers';

const CURRENT_YEAR = getYear(new Date());
const logger = loggerFunc('SetRemindersModel');

const SuccessText = (props) => {
  const { title, text, action } = props;
  return (
    <div className="modal-body success-text">
      <div className="header">
        <span className="myrac-icon-checked" />
        <h4 className="title success-title">{title}</h4>
        <p>{text}</p>
      </div>
      <div className="MyRacForm__center_button">
        <button
          className="MyRacForm__button MyRacForm__button--vertical-spacing MyRacForm__button--wide"
          type="button"
          data-dismiss="modal"
          onClick={action}
        >
          close
        </button>
      </div>
    </div>
  );
};

const FailureText = (props) => {
  const { title, text, action } = props;
  return (
    <div className="modal-body failure-text">
      <div className="header">
        <span className="myrac-icon-cancel" />
        <h4 className="title">{title}</h4>
        <p>{text}</p>
      </div>
      <div className="MyRacForm__center_button">
        <button
          className="MyRacForm__button MyRacForm__button--vertical-spacing MyRacForm__button--wide"
          type="button"
          data-dismiss="modal"
          onClick={action}
        >
          close
        </button>
      </div>
    </div>
  );
};

const getReminderItem = (reminder, localReminderValue, handleEmailAltertChange, handleCancelChange, handleDateChange) => {
  if (!reminder || !localReminderValue) {
    return null;
  }

  const {
    reminderType, vehicleId, emailReminderEnabled,
  } = reminder;

  const {
    selectedDay: selectedDayValue, selectedMonth: selectedMonthValue, validationError,
  } = localReminderValue;

  const handleEmailAlterChangeCallback = (value) => {
    handleEmailAltertChange(value, reminder);
  };

  const cancelAction = () => {
    handleCancelChange(reminder);
  };

  const expiryText = getReminderText(reminderType);

  const isEmailAlertBoxActive = !(selectedDayValue);

  return (
    <div className="reminderItem" key={`${reminderType} ${vehicleId}`}>
      <div className="row">
        <div className="col-sm-1 col-1 deleteContainer">
          <button
            type="button"
            className="clear-icon float-left"
            aria-hidden="true"
            data-dismiss="modal"
            title="clear"
            onClick={cancelAction}
          >
            <i className="myrac-icon-cancel" />
          </button>
        </div>
        <div className="col-sm-8 col-11">
          <div className="reminderTitle">
            {expiryText}
            <span>
              {' '}
              {validationError
                && <span className="MyRacForm__error-msg">a valid date required *</span>}
            </span>
          </div>
          <DropdownDayMonthPicker
            selectedDayValue={selectedDayValue}
            selectedMonthValue={selectedMonthValue}
            onChange={({ selectedDay, selectedMonth }) => {
              handleDateChange({ selectedDay, selectedMonth }, reminder, localReminderValue);
            }}
          />
        </div>
        <div className="col-sm-3 col-12 switchContainer">
          <div className="col-sm-12 col-5 float-left toggleTitle">Email alert</div>
          <ToggleControl onToggleChange={handleEmailAlterChangeCallback} checked={emailReminderEnabled} disabled={isEmailAlertBoxActive} />
        </div>
      </div>
    </div>
  );
};

const getBody = (saving, loading, saveVehicleReminder, reminders, reminderValues, handleEmailAltertChange, handleCancelChange, handleDateChange, action) => (
  <ComponentView showLoading={loading} onErrorModalClose={() => { }}>
    <div className="modal-body reminderBody">
      <form noValidate className="MyRacForm reminderForm" name="vehicleReminderform" onSubmit={saveVehicleReminder}>
        {reminders ? reminders.map((reminder) => {
          const localReminderValue = reminderValues.find((x) => x.reminderType === reminder.reminderType);
          return getReminderItem(reminder, localReminderValue, handleEmailAltertChange, handleCancelChange, handleDateChange);
        }) : null}
        <div className="MyRacForm__button-wrapper reminderButtonWrapper">
          <button
            className="MyRacForm__button MyRacForm__button--vertical-spacing MyRacForm__button--wide MyRacForm__button--grey"
            type="button"
            disabled={saving}
            data-dismiss="modal"
            onClick={action}
          >
            {getMessage(generalMessages.CANCEL_BUTTON_TEXT)}
          </button>
          <button
            className="MyRacForm__button MyRacForm__button--vertical-spacing MyRacForm__button--wide MyRacForm__button--left-margin"
            type="submit"
            disabled={(saving) || (reminderValues && reminderValues.find((x) => x.validationError))}
          >
            {saving ? <i className="fas fa-spinner fa-pulse" /> : getMessage(generalMessages.SAVE_BUTTON_TEXT)}
          </button>
        </div>
      </form>
    </div>
  </ComponentView>
);

const SetRemindersModel = (props) => {
  const {
    action, data, updateReminders, pageModel,
  } = props;

  let vehicleReminders = {};
  if (data) {
    const mappedData = Map(data);
    vehicleReminders = mappedData.toObject();
  }

  const initLocalData = (reminderData) => reminderData.reminders.map((reminder) => {
    const myDay = reminder.date ? getDate(new Date(reminder.date)) : undefined;
    const myMonth = reminder.date ? getMonth(new Date(reminder.date)) : undefined;
    return ({
      reminderType: reminder.reminderType,
      selectedDay: myDay,
      selectedMonth: myMonth,
      validationError: undefined,
      dateSetFirstTime: false,
    });
  });

  const [state, setState] = useState(vehicleReminders);
  const [motYear, setMotYear] = useState(CURRENT_YEAR);
  const [saving, setSaving] = useState(false);
  const [vehicleReminderState, setLocalState] = useState(
    {
      loading: true, motLoadError: false, errorText: undefined, saved: false, reminderValues: data ? initLocalData(data) : null,
    },
  );

  const handleEmailAltertChange = (value, reminder) => {
    if (reminder) {
      const newReminders = List(state.reminders).toJS();
      const currentReminder = newReminders.find((x) => x.reminderType === reminder.reminderType);
      const currentReminderIndex = newReminders.findIndex((x) => x.reminderType === reminder.reminderType);
      currentReminder.emailReminderEnabled = value;
      const newState = fromJS(state);
      const newValueObject = updateIn(newState, ['reminders'], (list) => list.splice(currentReminderIndex, 1, currentReminder));
      setState({ ...newValueObject.toJS() });
      setLocalState((prevState) => {
        const element = prevState && prevState.reminderValues.find((x) => x.reminderType === reminder.reminderType);
        if (element) {
          element.emailReminderEnabled = value;
        }
        return ({
          ...prevState,
        });
      });
    }
  };

  const handleDateChange = ({ selectedDay, selectedMonth, selectedYear }, reminder, localReminderValue, emailOverrideState) => {
    if (isValidDate(selectedDay, selectedMonth)) {
      const newReminders = List(state.reminders).toJS();
      const currentReminder = newReminders.find((x) => x.reminderType === reminder.reminderType);
      const currentReminderIndex = newReminders.findIndex((x) => x.reminderType === reminder.reminderType);
      currentReminder.date = format(new Date(selectedYear || motYear || CURRENT_YEAR, selectedMonth, selectedDay), 'yyyy-MM-dd');
      if (emailOverrideState !== undefined) {
        currentReminder.emailReminderEnabled = emailOverrideState;
      } else {
        currentReminder.emailReminderEnabled = localReminderValue.dateSetFirstTime === true ? currentReminder.emailReminderEnabled : true;
      }
      const newState = fromJS(state);
      const newValueObject = updateIn(newState, ['reminders'], (list) => list.splice(currentReminderIndex, 1, currentReminder));
      setState({
        ...newValueObject.toJS(),
      });
      setLocalState((prevState) => {
        const element = prevState && prevState.reminderValues.find((x) => x.reminderType === reminder.reminderType);
        if (element) {
          element.validationError = false;
          element.selectedDay = selectedDay;
          element.selectedMonth = selectedMonth;
          element.dateSetFirstTime = localReminderValue.dateSetFirstTime === false ? true : localReminderValue.dateSetFirstTime; // only reset email altert first time date is set
        }
        return ({
          ...prevState,
        });
      });
    } else if (selectedDay === undefined && selectedMonth === undefined) {
      const newReminders = List(state.reminders).toJS();
      const currentReminder = newReminders.find((x) => x.reminderType === reminder.reminderType);
      const currentReminderIndex = newReminders.findIndex((x) => x.reminderType === reminder.reminderType);
      currentReminder.date = undefined;
      currentReminder.emailReminderEnabled = false;
      const newState = fromJS(state);
      const newValueObject = updateIn(newState, ['reminders'], (list) => list.splice(currentReminderIndex, 1, currentReminder));
      setState({
        ...newValueObject.toJS(),
      });
      setLocalState((prevState) => {
        const element = prevState && prevState.reminderValues.find((x) => x.reminderType === reminder.reminderType);
        if (element) {
          element.validationError = false;
          element.selectedDay = selectedDay;
          element.selectedMonth = selectedMonth;
        }
        return ({
          ...prevState,
        });
      });
    } else { // either day or month have a value (but not both)
      const newReminders = List(state.reminders).toJS();
      const currentReminder = newReminders.find((x) => x.reminderType === reminder.reminderType);
      const currentReminderIndex = newReminders.findIndex((x) => x.reminderType === reminder.reminderType);
      currentReminder.date = undefined;
      const newState = fromJS(state);
      const newValueObject = updateIn(newState, ['reminders'], (list) => list.splice(currentReminderIndex, 1, currentReminder));
      setState({
        ...newValueObject.toJS(),
      });
      setLocalState((prevState) => {
        const element = prevState && prevState.reminderValues.find((x) => x.reminderType === reminder.reminderType);
        const myDay = selectedMonth === '' || selectedMonth === undefined ? undefined : selectedDay;
        const myMonth = selectedMonth === '' ? undefined : selectedMonth;
        if (element) {
          element.validationError = true;
          element.selectedDay = myDay;
          element.selectedMonth = myMonth;
        }
        return ({
          ...prevState,
        });
      });
    }
  };

  const handleCancelChange = (reminder) => {
    const newReminders = List(state.reminders).toJS();
    const currentReminder = newReminders.find((x) => x.reminderType === reminder.reminderType);
    const currentReminderIndex = newReminders.findIndex((x) => x.reminderType === reminder.reminderType);
    currentReminder.date = undefined;
    currentReminder.emailReminderEnabled = false;
    const newState = fromJS(state);
    const newValueObject = updateIn(newState, ['reminders'], (list) => list.splice(currentReminderIndex, 1, currentReminder));
    setState({
      ...newValueObject.toJS(),
    });
    setLocalState((prevState) => {
      const element = prevState && prevState.reminderValues.find((x) => x.reminderType === reminder.reminderType);
      if (element) {
        element.validationError = undefined;
        element.selectedDay = undefined;
        element.selectedMonth = undefined;
        element.dateSetFirstTime = false;
      }
      return ({
        ...prevState,
      });
    });
  };

  const saveVehicleReminder = async (e) => {
    e.preventDefault();
    setSaving(true);
    try {
      const newReminders = List(state.reminders);
      const savedReminders = await UpdateVehicleReminders(state.Registration, newReminders.toJS());
      if (savedReminders.responseCode === ResponseCode.SUCCESS) {
        setLocalState({
          ...state, loading: false, errorText: undefined, saved: true,
        });
        updateReminders({ vehicleId: state.vehicleId, reminders: state.reminders });
        setSaving(false);
      } else {
        setLocalState({
          ...state, loading: false, errorText: pageModel.saveErrorText, saved: false,
        });
        setSaving(false);
        logger.error(savedReminders.error);
      }
    } catch (err) {
      setLocalState({
        ...state, loading: false, errorText: pageModel.saveErrorText, saved: false,
      });
      setSaving(false);
      logger.error(err);
    }
  };

  useEffect(() => {
    trackEvent('myrac.vehicledetails_reminders_open');
    setLocalState({ loading: false, ...vehicleReminderState });
    if (data && data.Registration) {
      GetMotInfo(data.Registration).then(async (response) => {
        const reminder = state.reminders.find((r) => r.reminderType === 'MOT');
        const localReminder = vehicleReminderState.reminderValues.find((r) => r.reminderType === 'MOT');

        // Check to see if there was an existing date and set the email notification appropriately
        const emailOverrideState = reminder.date ? reminder.emailReminderEnabled : false;

        if (response && response.payload && response.payload.motTestExpiryDate) {
          // Extract date information
          setMotYear(getYear(new Date(response.payload.motTestExpiryDate)));
          handleDateChange({
            selectedDay: getDate(new Date(response.payload.motTestExpiryDate)),
            selectedMonth: getMonth(new Date(response.payload.motTestExpiryDate)),
            selectedYear: getYear(new Date(response.payload.motTestExpiryDate)),
          }, reminder, localReminder, emailOverrideState);
        }
      }).catch(() => setLocalState((prevState) => ({ ...prevState, motLoadError: true })))
        .finally(() => setLocalState((prevState) => ({ ...prevState, loading: false })));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!state) {
    return null;
  }

  const {
    reminders,
  } = state;

  const {
    reminderValues, loading, errorText, saved,
  } = vehicleReminderState;

  return (
    <>
      <div className="modal myrac" role="dialog" style={{ display: 'block', overflowY: 'auto' }}>
        <div className="modal-dialog">
          <div className="modal-content col-sm-12 col-12 p-3">
            <div className="modal-header float-right border-0">
              <button
                type="button"
                className="close ascii-cross"
                aria-hidden="true"
                data-dismiss="modal"
                onClick={action}
              >
                &#215;
              </button>
            </div>
            <div className="modal-header">
              {(!loading && !errorText && !saved) ? (
                <div style={{ width: '100%' }} className="row">
                  <h4 style={{ margin: '0px' }} className="modal-title reminderTitle">{ReactHtmlParser(pageModel.title)}</h4>
                  { ReactHtmlParser(vehicleReminderState.motLoadError ? pageModel.motDetailsFailure : pageModel.text, addAttributesToNode('p', { class: 'modal-small-header' })) }
                </div>
              ) : null}
            </div>
            {(!errorText && !saved)
              ? getBody(saving, loading, saveVehicleReminder, reminders, reminderValues, handleEmailAltertChange, handleCancelChange, handleDateChange, action)
              : null}
            {(!loading && !errorText && saved) ? <SuccessText title={pageModel.successTitle} text={pageModel.successDetail} action={action} /> : null}
            {(!loading && errorText && !saved) ? <FailureText title={pageModel.errorTitle} text={errorText} action={action} /> : null}
          </div>
        </div>
      </div>
      <div
        className="modal-backdrop fade in Modal__block"
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateReminders: (reminders) => {
    dispatch(VehicleActions.updateVehicleReminders(reminders));
  },
});

export default connect(null, mapDispatchToProps)(React.memo(SetRemindersModel));
