import React from 'react';
import styles from './PageTitle.module.scss';

const PageTitle = ({ text, subheader = null, greyColor = null }) => (
  <div className={styles.pageTitle}>
    <h3>
      {greyColor ? <span className={styles.greyColor}>{text}</span> : text}
    </h3>
    {subheader && <p className={styles.subheader}>{subheader}</p>}
  </div>
);

export default PageTitle;
