import React, { useState, useEffect } from 'react';
import trackEvent from 'services/AnalyticsService';
import { GetOrangePointsRewards } from 'services/MyRacService';
import Spinner from 'components/Spinner';
import Banner from './Banner';
import TermsAndConditions from './TermsAndConditions';
import Options from './Options';
import ConfirmationScreen from './ConfirmationScreen';
import ErrorMessage from './ErrorMessage';
import Container, { CONTAINER_TYPES } from './Container';

const RewardsScreen = () => {
  const [loading, setLoading] = useState(true);
  const [pageModel, setPageModel] = useState(null);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const fetchOrangePointsEligibility = async () => {
    try {
      const orangePointsRewards = await GetOrangePointsRewards();
      if (orangePointsRewards?.payload) {
        setPageModel(orangePointsRewards.payload);
      }
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!response) {
      fetchOrangePointsEligibility();
    }
  }, []);

  if (loading) {
    return <Spinner bootSpinner />;
  }

  if (!loading && error?.status !== 500) {
    if (!pageModel?.memberHasNotClaimedReward || response?.foundExistingRewardWhenClaimAttempted) {
      trackEvent('MyRAC_OrangePoints_Error_Redeemed');
      return (
        <ErrorMessage title="Hmm, looks like you've already got this offer">
          If this isn&apos;t right, or you need to discuss your renewal or Orange Points, please call us on <a href="tel:0330 159 0369">0330 159 0369</a>
        </ErrorMessage>
      );
    }
    if (!pageModel?.isEligibleForOrangePointsRewards || response?.memberIneligibleWhenClaimAttempted) {
      trackEvent('MyRAC_OrangePoints_Error_Eligibility');
      return (
        <ErrorMessage title="Sorry, we're having trouble applying your points">
          Give us a call on <a href="tel:0330 159 8691">0330 159 8691</a> and we&apos;ll make sure you don&apos;t miss out.
        </ErrorMessage>
      );
    }
    if (!pageModel?.isSspBusinessWindowOpen || response?.sspBusinessWindowClosedWhenClaimAttempted) {
      trackEvent('MyRAC_OrangePoints_Error_Closure');
      return (
        <ErrorMessage title="You just missed us!" type="CLOSED">
          We&apos;ve locked up shop for the night. Come back at 6am and our systems will be ready to redeem your Orange Points.
        </ErrorMessage>
      );
    }
  }

  if (pageModel && !error) {
    if (response?.orangePointsOfferRedeemed) {
      trackEvent('MyRAC_OrangePoints_ConfirmationPage');
      return (
        <Container type={CONTAINER_TYPES.CONFIRMATION}>
          <ConfirmationScreen response={response} />
        </Container>
      );
    }

    trackEvent('MyRAC_OrangePoints_LandingPage');
    return (
      <Container type={CONTAINER_TYPES.GREY_BACKGROUND}>
        <Banner orangePointsAmount={pageModel.orangePoints} />
        {pageModel.smrDiscountOfferAmount && pageModel.renewalDiscountOfferAmount && (
          <Options
            smrDiscountOfferAmount={pageModel.smrDiscountOfferAmount}
            renewalDiscountOfferAmount={pageModel.renewalDiscountOfferAmount}
            setResponse={setResponse}
          />
        )}
        <TermsAndConditions
          smrDiscountOfferAmount={pageModel.smrDiscountOfferAmount}
          renewalDiscountOfferAmount={pageModel.renewalDiscountOfferAmount}
        />
      </Container>
    );
  }

  trackEvent('MyRAC_OrangePoints_Error_Technical');
  return (
    <ErrorMessage title="Uh oh! Something&apos;s gone wrong">
      We&apos;re experiencing some technical difficulties.
      Try refreshing the page or come back later.<br />
      Prefer to talk? Call us on <a href="tel:0330 159 8691">0330 159 8691</a>
    </ErrorMessage>
  );
};

export default RewardsScreen;
