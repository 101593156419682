import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as VehicleActions from 'actions/vehiclesInfoActions';
import { getMessage } from 'services/MessageService';
import { GetVehiclesData, DeleteVehicle, FindVehicle } from 'services/VehicleService';
import trackEvent from '../../services/AnalyticsService';
import { getVehicle, regNoRegex } from '../../utils/vehicleHelpers';
import isEmpty from '../../utils/objectHelpers';
import { ResponseCode } from '../../services/models/httpResponse';
import { loggerFunc } from '../../utils/logger';
import { vehicleErrors } from '../../constants/cms/messageConstants';
import ComponentView from '../../common/ComponentView';
import SuccessText from './MessageDialogs/SuccessText';
import FailureText from './MessageDialogs/FailureText';
import ConfirmationText from './MessageDialogs/ConfirmationText';
import SearchVehicleText from './VehicleLookup/SearchVehicleText';
import ManualAndListVehicle from './VehicleLookup/ManualAndListVehicle';

const logger = loggerFunc('AddVehicles');

const AddVehicle = ({ vehicles, isInEditMode, vehicleId, action, forceQuit, pageModel, addedVehicleCallback, vehicleRemindersCardLoading, setVehicleRemindersCardLoading }) => {
  const [loading, setLoading] = useState();
  const [saved, setSaved] = useState();
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState();
  const [invalidReg, setInvalidReg] = useState();
  const [errorText, setErrorText] = useState();
  const [errorHeaderText, setErrorHeaderText] = useState();
  const [searchingVehicle, setSearchingVehicle] = useState(false);
  const [vehicle, setVehicle] = useState(getVehicle(vehicleId, vehicles.vehicleInfo.reminderVehicles));
  const [showManualEntry, setShowManualEntry] = useState(true);
  const title = isInEditMode ? pageModel.screenTitles.editTitle : pageModel.screenTitles.addTitle;

  const handleError = (e, text, headerText) => {
    setErrorText(text);
    setErrorHeaderText(headerText);
    setLoading(false);
    setSaved(false);
    logger.error(text, e);
  };

  const findVehicle = async (event) => {
    event.preventDefault();
    const regNo = event.target.regNo.value;
    if (!isEmpty(regNo) && regNoRegex.test(regNo)) {
      setInvalidReg(false);
      setSearchingVehicle(false);
      try {
        let vehicleinfo;
        const vehicleList = await FindVehicle({
          Registration: regNo,
        });
        const normalisedRegno = regNo.replace(/\s/g, '').toUpperCase();

        if (vehicleList.responseCode === ResponseCode.SUCCESS) {
          if (!vehicleList.payload) {
            handleError(new Error('Error finding vehicle response code'), getMessage(vehicleErrors.VEHICLE_ERROR_MESSAGE));
          } else {
            vehicleinfo = vehicleList.payload;
            vehicleinfo.Registration = normalisedRegno;
            setVehicle(vehicleinfo);
            setSearchingVehicle(false);
            setShowManualEntry(true);
          }
        }
      } catch (err) {
        if (err.error.statusCode === 400) {
          handleError(err, getMessage(vehicleErrors.VEHICLE_INVALID_FORMAT));
        } else {
          handleError(err, getMessage());
        }
      }
    } else {
      setInvalidReg(true);
    }
  };

  const removeIdFromLoadingList = () => vehicleRemindersCardLoading.filter((id) => id !== vehicleId);

  const confirmationDialogOkCallback = async (event) => {
    event.preventDefault();
    if (setVehicleRemindersCardLoading) { setVehicleRemindersCardLoading([...vehicleRemindersCardLoading, vehicleId]); } else { setLoading(true); }
    action();
    setSaved(false);
    try {
      const deletedVehicle = await DeleteVehicle(vehicle.Registration);
      if (deletedVehicle.responseCode === ResponseCode.SUCCESS) {
        await GetVehiclesData();
        if (setVehicleRemindersCardLoading) {
          setVehicleRemindersCardLoading(removeIdFromLoadingList());
        } else {
          setLoading(false);
        }
      } else {
        handleError('Deleted Vehicle Error', getMessage(vehicleErrors.vehicleErrorMessage));
        if (setVehicleRemindersCardLoading) {
          setVehicleRemindersCardLoading(removeIdFromLoadingList());
        } else {
          setLoading(false);
        }
      }
    } catch (err) {
      handleError(err, getMessage(vehicleErrors.vehicleErrorMessage));
      if (setVehicleRemindersCardLoading) { setVehicleRemindersCardLoading(removeIdFromLoadingList()); } else { setLoading(false); }
    }
  };

  const confirmationDialogCancelCallback = async (event) => {
    event.preventDefault();
    setLoading(false);
    setSaved(false);
    setShowDeleteConfirmationDialog(false);
  };

  const deleteVehicle = (event) => {
    event.preventDefault();
    trackEvent('myrac.vehicledetails_deletevehicle_open');
    setLoading(false);
    setSaved(false);
    setShowDeleteConfirmationDialog(true);
  };

  useEffect(() => {
    trackEvent(isInEditMode ? 'myrac.vehicledetails_editvehicle_open' : 'myrac.vehicledetails_addvehicle_open');
  }, [isInEditMode]);

  return (
    <ComponentView errorText={errorText} errorHeaderText={errorHeaderText} onErrorModalClose={forceQuit || action}>
      <div className="modal myrac" role="dialog" style={{ display: 'block', overflowY: 'auto' }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div>
              {(!showDeleteConfirmationDialog) && (
                <div className="modal-header float-right border-0">
                  <button type="button" className="close ascii-cross" aria-hidden="true" onClick={forceQuit || action}>&#215;</button>
                </div>
              )}
              {(!loading && !errorText && saved) && (<SuccessText title={pageModel.responseScreens.successTitle} text={pageModel.responseScreens.successDetail} action={action} />)}
              {(!loading && errorText && !saved) && (<FailureText title={pageModel.responseScreens.errorTitle} text={errorText} action={action} />)}
              {(showDeleteConfirmationDialog) && (<ConfirmationText title={pageModel.deleteTitle} text={pageModel.deleteText} onOkCallback={confirmationDialogOkCallback} cancelCallback={confirmationDialogCancelCallback} />)}
            </div>
            {searchingVehicle && !showDeleteConfirmationDialog
              && (
                <SearchVehicleText pageModel={pageModel.searchVehicle} />
              )}
            { !searchingVehicle && showManualEntry && !showDeleteConfirmationDialog
              && (
                <ManualAndListVehicle onDeleteCallback={deleteVehicle} vehicledetails={vehicle} vehicleInfo={vehicle} toggle={action} close={forceQuit} isInEditMode={isInEditMode} pageModel={pageModel} addedVehicleCallback={addedVehicleCallback} vehicles={vehicles} />
              )}
            { !searchingVehicle && !showManualEntry && !showDeleteConfirmationDialog
              && (
                <div className="modal-body">
                  <div>
                    <h4 className="modal-title add-vehicle-title">{title}</h4>
                  </div>
                  <form noValidate className="MyRacForm" name="vehicleRegForm" onSubmit={findVehicle}>
                    <div className="MyRacForm__Modal">
                      <input
                        name="regNo"
                        className="MyRacForm__form-element MyRacForm__input-extra-height"
                        type="text"
                        disabled={showManualEntry}
                        required
                      />
                    </div>
                    {invalidReg
                      && <span className="MyRacForm__error-msg text-center">{pageModel.fieldValidation.invalidReg}</span>}
                    <div className="Manual_entry">
                      <button
                        type="button"
                        className="MyRacForm__button-link MyRacForm__enter-address-manual"
                        onClick={() => { setShowManualEntry(true); }}
                      >
                        {pageModel.enterDetailsManuallyButtonText}
                      </button>
                    </div>

                    <div className="button_wrapper">
                      <button
                        className="MyRacForm__button MyRacForm__button--vertical-spacing MyRacForm__button--wide MyRacForm__button--grey"
                        type="button"
                        data-dismiss="modal"
                        onClick={forceQuit || action}
                      >
                        {pageModel.cancelButtonText}
                      </button>
                      <button
                        className="MyRacForm__button MyRacForm__button--vertical-spacing MyRacForm__button--wide MyRacForm__button--left-margin"
                        type="submit"
                        disabled={showManualEntry}
                      >
                        <span>{pageModel.findVehicleButtonText}</span>
                      </button>
                    </div>
                  </form>
                </div>
              )}
          </div>
        </div>
      </div>
      <div
        className="modal-backdrop fade in Modal__block"
      />
    </ComponentView>
  );
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  policy: state.policy,
  vehicles: state.vehicles,
});

const mapDispatchToProps = (dispatch) => ({
  deleteVehicle: (vehicleId) => {
    dispatch(VehicleActions.deleteVehicle(vehicleId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddVehicle);
