import React, { useEffect, useRef } from 'react';
import myRacLogo from 'assets/images/logo/myrac.svg';
import logout from 'assets/images/icons/log-out.svg';
import useViewport from 'utils/useViewportHelper';
import { ROOT, LOGOUT, SESSION_EXPIRED, MY_COVER, MEMBER_REWARDS, VEHICLE_DETAILS, PERSONAL_DETAILS, DOCUMENTS, ACCOUNT_DETAILS } from 'constants/routeConstants';
import { NavLink, useLocation } from 'react-router-dom';
import { getState } from 'store';

const ScrollToElement = () => {
  const { isMobile } = useViewport();

  const scrollRef = useRef(null);

  useEffect(() => {
    if (isMobile) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth', inline: 'center' });
      setTimeout(() => window.scrollTo(0, 0), 100);
    }
  }, []);

  return isMobile ? <div ref={scrollRef} style={{ position: 'absolute', left: 0, width: '100%', height: '1px' }} /> : null;
};

const MenuItem = ({ url, text }) => (
  <NavLink to={url} className="nav-item nav-link mx-1 border position-relative">
    {({ isActive }) => (
      <>
        {isActive && <ScrollToElement />}
        {text}
      </>
    )}
  </NavLink>
);

const Header = () => {
  const { session: { isLoggedIn } } = getState();
  const { isMobile } = useViewport();
  const { pathname } = useLocation();

  const userSession = isLoggedIn && pathname !== LOGOUT && pathname !== SESSION_EXPIRED;

  const links = [
    { key: 'ROOT', url: ROOT, text: 'Homepage' },
    { key: 'MY_COVER', url: MY_COVER, text: 'My cover' },
    { key: 'MEMBER_REWARDS', url: MEMBER_REWARDS, text: 'Member rewards' },
    { key: 'VEHICLE_DETAILS', url: VEHICLE_DETAILS, text: 'Vehicle details' },
    { key: 'PERSONAL_DETAILS', url: PERSONAL_DETAILS, text: 'Personal details' },
    { key: 'DOCUMENTS', url: DOCUMENTS, text: 'Membership documents' },
    { key: 'ACCOUNT_DETAILS', url: ACCOUNT_DETAILS, text: 'Account settings' },
  ];

  return (
    <div className="container">
      <div className={`row p-2 ${isMobile ? 'my-1' : 'my-3'}`}>
        <div className="col">
          <NavLink to={ROOT}>
            <img
              src={myRacLogo}
              className={isMobile ? 'my-2' : ''}
              height={isMobile ? '25px' : '46px'}
              width={isMobile ? '100px' : '183px'}
              alt="Rac Logo"
            />
          </NavLink>
        </div>
        {userSession && (
          <div className="col text-right mx-2 my-2">
            <img src={logout} width={isMobile ? '20px' : '30px'} className="mx-2" alt="Logout" />
            <NavLink to={LOGOUT} className="text-dark">Logout</NavLink>
          </div>
        )}
      </div>
      {userSession && (
        <div className={`row ${isMobile ? 'pt-1' : 'pt-2'}`}>
          <div className="col">
            <div className="navbar navbar-expand navbar-light bd-navbar overflow-auto">
              <div className="navbar-nav-scroll">
                <div className="navbar-nav nav-pills nav-fill bd-navbar flex-row">
                  {links.map((link) => <MenuItem url={link.url} text={link.text} key={link.key} />)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
