export function setVehicles(vehicles) {
  return {
    type: 'SET_VEHICLE',
    payload: vehicles,
  };
}

export function updateVehicleReminders(reminders) {
  return {
    type: 'UPDATE_VEHICLE_REMINDERS',
    payload: reminders,
  };
}

export function deleteVehicle(vehicleId) {
  return {
    type: 'DELETE_VEHICLE',
    payload: vehicleId,
  };
}

export function vehiclesErrorOccured(hasError) {
  return {
    type: 'VEHICLE_HAS_ERROR',
    payload: hasError,
  };
}

export function addVehicles(vehicle) {
  return {
    type: 'ADD_VEHICLE',
    payload: vehicle,
  };
}

export function updateVehicles(vehicle) {
  return {
    type: 'UPDATE_VEHICLE',
    payload: vehicle,
  };
}
