import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getVehicleMotHistory } from 'services/VehicleService';
import styles from './VehicleRemindersCard.module.scss';
import TextButton from '../../../shared/components/TextButton/TextButton';
import ReminderItem from './ReminderItem';
import SMRBanner from './SMRBanner';
import Spinner from '../../Spinner';
import { COVER_STATES } from '../../../constants/vehicleConstants';
import { formatReminderItems } from '../../../utils/vehicleHelpers';

const VehicleRemindersCard = ({ session, vehicle, coverStatus, coverText, remindersList, vehicleEditOnClick, reminderEditOnClick, motHistoryButtonClick, setShowVehicleHistoryModal, vehicleRemindersCardLoading, reminderSMRData, policyVehicleBreakdownExtras }) => {
  const [remindersListState, setRemindersListState] = useState();
  const [coverStatusStyles, setCoverStatusStyles] = useState(styles.statusInactive);
  const [loading, setLoading] = useState(false);

  const vehicleName = `${vehicle.Make} ${vehicle.Model}`;

  const styleSelector = () => {
    const activeList = [COVER_STATES.IS_COVERED_BY_BREAKDOWN, COVER_STATES.IS_COVERED_FOR_REGISTERED_USERS, COVER_STATES.IS_HYBRID_COVERED_BY_BREAKDOWN, COVER_STATES.IS_MEMBERSHIP_ONLY, COVER_STATES.IS_PAY_AS_YOU_GO_COVERED];

    if (activeList.includes(coverStatus)) {
      return styles.statusActive;
    }
    return styles.statusInactive;
  };

  const fetchMotData = async () => {
    setLoading(true);
    getVehicleMotHistory(vehicle.Registration, session)
      .then((response) => {
        if (response) {
          motHistoryButtonClick(vehicle, response.payload);
        } else {
          motHistoryButtonClick(vehicle, null);
        }
      })
      .catch(motHistoryButtonClick(vehicle, null))
      .finally(() => { setLoading(false); setShowVehicleHistoryModal(true); });
  };

  useEffect(() => {
    setRemindersListState(formatReminderItems(remindersList));
    setCoverStatusStyles(styleSelector());
  }, [remindersList]);

  const lozenges = (breakdownExtras) => (
    <div>
      {breakdownExtras.map((item) => (
        <div key={item.productName + item.productId}>
          <span className={styles.lozenge}>
            {`Covered for  ${item.productName}`}
          </span>
        </div>
      ))}
    </div>
  );

  let breakdownExtraFormattedDisplay = '';
  if (policyVehicleBreakdownExtras.length === 1) {
    breakdownExtraFormattedDisplay = policyVehicleBreakdownExtras[0].productName;
  } else if (policyVehicleBreakdownExtras.length > 1) {
    policyVehicleBreakdownExtras.forEach((item, index) => {
      if (index === 0) {
        breakdownExtraFormattedDisplay += policyVehicleBreakdownExtras[index].productName;
        return;
      }
      breakdownExtraFormattedDisplay += ` and ${policyVehicleBreakdownExtras[index].productName}`;
    });
  }

  return (
    <div className={`${styles.container} ${coverStatusStyles}`}>
      {vehicleRemindersCardLoading ? <div className={styles.spinnerContainer}><Spinner addClass="pt-0" bootSpinner /></div> : (
        <>
          <div className={styles.vehicleContainer}>
            <div className={styles.headerContainer}>
              <span className={`${styles.registration} ${coverStatusStyles} sessioncamhidetext`}>{vehicle.Registration}</span>
              <TextButton buttonText="Edit" orange textUnderline noPadding onClickHandler={vehicleEditOnClick} />
            </div>
            <h3 className={`${styles.vehicleName} ${coverStatusStyles}`}>{vehicleName}</h3>
            {vehicle.isEditedOnRenewalQuote && (
              <div className={styles.confirmationCopy}>
                This update will be applied when your policy renews.
                If you break down before then, you&apos;re still covered,
                but you&apos;ll need to call us instead of using myRAC.
                {policyVehicleBreakdownExtras.length > 0 && (
                  <>
                    <br /><br />
                    {breakdownExtraFormattedDisplay} for {vehicle.Registration} is available 7 days after updating your vehicle.
                  </>
                )}
              </div>
            )}
            <div className={`${styles.statusText} ${coverStatusStyles}`}>{coverText}</div>
            {vehicle.isPolicyVehicle && policyVehicleBreakdownExtras && lozenges(policyVehicleBreakdownExtras)}
          </div>
          <div className={styles.verticalLine} />
          <div className={styles.vehicleRemindersContainer}>
            <div className={styles.headerContainer}>
              <h4 className={styles.title}>Reminders</h4>
              <TextButton buttonText="Edit" orange textUnderline noPadding onClickHandler={reminderEditOnClick} />
            </div>
            {reminderSMRData?.heroBanner && remindersListState?.service && vehicle?.Registration && (
              <SMRBanner
                data={reminderSMRData.heroBanner}
                vehicleRegistration={vehicle.Registration}
                serviceDate={remindersListState?.service?.date}
              />
            )}
            <div className={styles.reminderContainer}>
              <ReminderItem reminder={remindersListState?.service} reminderSMRBtn={reminderSMRData?.remindersMoreInfo} vehicleRegistration={vehicle.Registration} />
              <ReminderItem reminder={remindersListState?.tax} />
              <ReminderItem reminder={remindersListState?.mot} buttonText="View MOT history" buttonAction={fetchMotData} loading={loading} />
              <ReminderItem reminder={remindersListState?.insurance} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

export default connect(mapStateToProps)(VehicleRemindersCard);
