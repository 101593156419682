import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as RouteConstants from 'constants/routeConstants';
import trackEvent from 'services/AnalyticsService';
import { GetVehiclesData, ConfirmRenewalPolicyVehiclesCorrect } from 'services/VehicleService';
import styles from './VehiclesConfirmation.module.scss';

const VehicleItem = ({ vehicleId, reg, make, model }) => {
  const navigate = useNavigate();

  const changeVehicleHandler = () => {
    trackEvent('MyRAC_ChangeVehicle_NBA_Change_Clicked');
    navigate({
      pathname: RouteConstants.VEHICLE_DETAILS,
      search: `?${RouteConstants.PARAMS.CHANGE_VEHICLE_ID}=${vehicleId}`,
    });
  };

  return (
    <div className={styles.item}>
      <div className={styles.buttons}>
        <span className={styles.reg}>{reg}</span>
        <button type="button" className={styles.changeBtn} onClick={changeVehicleHandler}>
          Change vehicle
        </button>
      </div>
      <div className={styles.details}>
        <span className={styles.make}>{make}</span>
        <span className={styles.model}>{model}</span>
      </div>
    </div>
  );
};

const VehiclesConfirmation = ({ onSuccessHandler, onErrorHandler }) => {
  const vehiclesData = useSelector((state) => state.vehicles.vehicleInfo);
  const [loading, setLoading] = useState(false);
  const [hideNBA, setHideNBA] = useState(false);

  const handlePolicyVehiclesCorrect = async () => {
    try {
      setLoading(true);
      const response = await ConfirmRenewalPolicyVehiclesCorrect();
      if (response) {
        await GetVehiclesData();
        setHideNBA(true);
        trackEvent('MyRAC_ChangeVehicle_NBA_Correct_Clicked');
        onSuccessHandler();
      }
      setLoading(false);
    } catch (err) {
      onErrorHandler();
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!hideNBA) {
      trackEvent('MyRAC_ChangeVehicle_NBA_Displayed');
    }
  }, []);

  if (hideNBA) {
    return null;
  }

  const allVehicles = vehiclesData?.reminderVehicles;
  const policyVehicles = allVehicles ? allVehicles.filter((vehicle) => vehicle.isPolicyVehicle === true) : [];
  const vehicleNumber = policyVehicles.length;

  // Specific styling for different number of vehicles - 3 in a row when 3, 5 and 6 vehicles available
  let listStylesClass = styles.list;
  if (vehicleNumber === 3 || vehicleNumber > 4) {
    listStylesClass = styles.listWithThreeColumns;
  } else if (vehicleNumber === 1) {
    listStylesClass = styles.singleItemList;
  }

  const wrapperStylesClass = vehicleNumber === 1 ? styles.oneVehicleWrapper : styles.wrapper;
  const daysToRenewal = vehiclesData?.daysToRenewal;

  return (
    <div className={styles.stretchContainer}>
      <div className={styles.container}>
        <div className={wrapperStylesClass}>
          <h2 className={styles.header}>
            { vehicleNumber === 1 ? 'Is this still the right vehicle?' : 'Are these still the right vehicles?' }
          </h2>
          {daysToRenewal >= 0 && <p className={styles.subheading}>Your breakdown cover renews in {daysToRenewal} {daysToRenewal > 1 ? 'days' : 'day'}</p>}
          <div className={listStylesClass}>
            {vehicleNumber > 0 && policyVehicles.map((vehicle) => <VehicleItem key={vehicle.vehicleId} vehicleId={vehicle.vehicleId} reg={vehicle.registration} make={vehicle.make} model={vehicle.model} />)}
          </div>
          <div className={styles.actions}>
            <button type="button" className={styles.correctBtn} onClick={handlePolicyVehiclesCorrect}>
              {loading ? (
                <>
                  <div className="spinner-border spinner-border-sm mx-2" role="status" />
                  Loading...
                </>
              ) : (
                'That\'s correct'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehiclesConfirmation;
