import React from 'react';
import { useSelector } from 'react-redux';
import ReactHtmlParser from '@orrisroot/react-html-parser';
import { addAttributesToNode } from 'utils/ContentHelpers';
import { getHelperTextByCover, getHelperTextByPolicy } from 'utils/vehicleHelpers';
import { getCoverTypeFlags } from 'views/MyRac/MyCover/utils';

const AdditionalEditInfo = ({ pageModel, isInEditMode, canEditVehicle, vehicle }) => {
  const policyInfo = useSelector((state) => state.policy.policyInfo);
  const cover = getCoverTypeFlags(policyInfo);

  const getHelperText = () => {
    if (!policyInfo) { return null; }

    if (isInEditMode) {
      return getHelperTextByCover(vehicle.coverState, pageModel.editHelperText);
    }
    return getHelperTextByPolicy(policyInfo, false, pageModel.addHelperText);
  };

  if (cover.isVbm && vehicle.isPolicyVehicle && isInEditMode && canEditVehicle) {
    return (
      <p className="text-center">
        You can change your vehicle to another car,
        motorbike, or small van (under 3.5 tonnes),
        less than 6.4 meters long and less than 2.55 meters wide.
        The vehicle will only be covered for occasional business use
        if you hold the occasional business use add-on,
        please check your policy schedule.
        <br /><br />
      </p>
    );
  }
  if (!cover.isVbm && isInEditMode && canEditVehicle) {
    return (
      <p className="text-center">
        You can add any vehicles for reminders,
        but  you&apos;ll only be covered if you breakdown in a car,
        motorbike, or small van (under 3.5 tonnes),
        less than 6.4 meters long and less than 2.55 meters wide.
        The vehicle will only be covered for occasional business use
        if you hold the occasional business use add-on,
        please check your policy schedule.
        <br /><br />
      </p>
    );
  }
  if (isInEditMode && !canEditVehicle) {
    return <p className="text-center text-danger">{ReactHtmlParser(pageModel.notAbleToEdit)}</p>;
  }
  return ReactHtmlParser(getHelperText(), addAttributesToNode('p', { class: 'text-center' }));
};

export default AdditionalEditInfo;
