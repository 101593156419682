import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import RenderLink from '../../../components/RenderLink';
import * as RouteConstants from '../../../constants/routeConstants';
import styles from './MyCover.module.scss';
import trackEvent from '../../../services/AnalyticsService';
import membershipStatus from '../../../constants/membershipType';
import CollapsibleSection from './CollapsibleSection';

const VehiclesCovered = ({ items, coverType, extraVehicleUpgradeAllowed }) => {
  const navigate = useNavigate();

  const translation = {
    title: {
      singular: 'Vehicle covered',
      plural: 'Vehicles covered',
    },
    description: {
      [membershipStatus.hybrid]: 'The vehicles listed below are covered no matter who\'s driving',
      [membershipStatus.vehicle]: 'The vehicles listed below are covered no matter who is driving as long as they are registered at your address',
    },
    actions: {
      add: 'Add a vehicle',
      view: 'View',
    },
  };

  let ctaButtonLabel = translation.actions.add;
  let ctaDisabled = false;

  if (!extraVehicleUpgradeAllowed) {
    ctaDisabled = true;
    ctaButtonLabel = undefined;
  }

  const onAdd = useCallback(() => {
    if (!extraVehicleUpgradeAllowed) { return; }

    trackEvent('MyRAC_MyCover_VehiclesCoveredAdd');
    if (extraVehicleUpgradeAllowed) {
      navigate({
        pathname: RouteConstants.PRODUCT_UPGRADE_PAGE,
        search: '?product=Extra Vehicle',
      });
    } else {
      navigate({
        pathname: RouteConstants.VEHICLE_DETAILS,
        search: '?product=Extra Vehicle',
      });
    }
  }, [navigate, extraVehicleUpgradeAllowed]);

  return (
    <CollapsibleSection title={items.length > 1 ? translation.title.plural : translation.title.singular}>
      <p className="my-3">{translation.description[coverType]}</p>
      <div className="clearfix flex-column my-2">
        {items.map((item) => (
          <div key={item.Registration} className="item d-flex justify-content-between py-2">
            <div>
              <strong>{item.Registration}</strong> - {item.Make} {item.Model}
            </div>
            <RenderLink
              url={RouteConstants.VEHICLE_DETAILS}
              className={styles.actionLink}
              analytics="MyRAC_MyCover_VehiclesCoveredView"
            >
              {translation.actions.view}
            </RenderLink>
          </div>
        ))}
      </div>
      {ctaButtonLabel && (
        <button
          className={`${styles.outlineButton} my-1 px-4`}
          onClick={onAdd}
          type="button"
          disabled={ctaDisabled}
        >
          {ctaButtonLabel}
        </button>
      )}
    </CollapsibleSection>
  );
};

export default VehiclesCovered;
