import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { LOGIN } from 'constants/routeConstants';
import { useApplicationState } from 'hooks/applicationState-Context';
import useReferrerParam from '../../hooks/useReferrerParam';
import ScrollToTop from '../../common/ScrollToTop';
import RootHeader from '../../views/MyRac/RootHeader/Header';
import Header from '../../views/MyRac/Header';
import Footer from '../../views/MyRac/Footer';
import Loading from '../../views/MyRac/Loading';
import styles from './MainContent.module.scss';

const MainContent = () => {
  const [loading, setLoading] = useState(true);
  // Flag to indicate referral from App (Webview)
  const isAppView = useReferrerParam();

  // Hook used to get current location
  const { pathname } = useLocation();

  // Use state to determine page loading
  const { appState } = useApplicationState();

  useEffect(() => {
    /**
     * Fixing the React's: Can't perform a React state update
     * on an unmounted component for RootHeader with
     * the seperate header render statements below.
     * RootHeader was trying to render again
     * before routing to Homepage after login
     */
    setLoading(appState.loading.status);
  }, [appState.loading.status]);

  return (
    <>
      {!isAppView && !loading && pathname === LOGIN ? <RootHeader /> : null}
      {!isAppView && pathname !== LOGIN ? <Header /> : null}
      { /* On mobile, some pages do not scroll to the top, adding in ScrollToTop custom hook which was missing */ }
      <ScrollToTop />
      <main>
        <section className={styles.sectionWrapper}>
          <Loading />
          <Outlet />
        </section>
      </main>
      {!isAppView && <Footer />}
    </>
  );
};

export default MainContent;
