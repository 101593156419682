import { dispatch } from 'store';
import { setVehicles, vehiclesErrorOccured } from 'actions/vehiclesInfoActions';
import * as APIConstants from 'constants/apiConstants';
import { post, put, get, del } from 'utils/httpHelpers';
import { COVER_STATES } from 'constants/vehicleConstants';
import { GetLatestPolicyInfo } from 'services/MyRacService';
import { ResponseCode } from 'services/ContentService';
import trackEvent from 'services/AnalyticsService';
import { loggerFunc } from 'utils/logger';

const logger = loggerFunc('MyRACService');

export const GetVehiclesData = async () => {
  try {
    logger.debug('retrieving vehicles and reminders');
    const vehicles = await get(APIConstants.API_VEHICLES, null, null, false, APIConstants.version.V3);
    logger.debug('storing vehicles and reminders');
    if (vehicles.responseCode === ResponseCode.SUCCESS && vehicles.payload) {
      dispatch(setVehicles(vehicles.payload));
    }
  } catch (error) {
    logger.error('failed to retrieve vehicles data due to', error);
    dispatch(vehiclesErrorOccured(true));
  }
};

export const AddNewVehicle = async (payload) => {
  trackEvent('myrac.vehicledetails_addvehicle_submit');
  return post(APIConstants.API_VEHICLES, null, null, payload, true, APIConstants.version.V3);
};

export const UpdateVehicle = async (registration, payload, coverState) => {
  const pathParams = {
    registration,
  };
  switch (coverState) {
    case COVER_STATES.IS_COVERED_BY_BREAKDOWN:
    case COVER_STATES.IS_HYBRID_COVERED_BY_BREAKDOWN:
    case COVER_STATES.IS_PAY_AS_YOU_GO_COVERED:
      trackEvent('myrac.vehicledetails_editsubmit_VBM');
      break;
    case COVER_STATES.IS_COVERED_FOR_REGISTERED_USERS:
    case COVER_STATES.IS_HYBRID_COVERED_FOR_REGISTERED_USERS:
    case COVER_STATES.IS_MEMBERSHIP_ONLY:
      trackEvent('myrac.vehicledetails_editsubmit_PBM');
      break;
    default:
      trackEvent('myrac.vehicledetails_editsubmit_Reminder');
      break;
  }
  const actionResponse = await put(APIConstants.API_VEHICLE_BY_REGISTRATION, null, pathParams, payload, true, APIConstants.version.V3);
  if (actionResponse.responseCode === ResponseCode.SUCCESS) {
    GetLatestPolicyInfo();
  }
  return actionResponse;
};

export const UpdateVehicleReminders = async (registration, payload) => {
  const pathParams = {
    registration,
  };
  trackEvent('myrac.vehicledetails_reminders_saved');
  const actionResponse = await put(APIConstants.API_UPDATE_VEHICLES_REMINDERS, null, pathParams, payload, true, APIConstants.version.V3);
  if (actionResponse.responseCode === ResponseCode.SUCCESS) {
    await GetVehiclesData();
  }
  return actionResponse;
};

export const DeleteVehicle = async (registration) => {
  const pathParams = {
    registration,
  };

  trackEvent('myrac.vehicledetails_deletevehicle_submit');
  const actionResponse = await del(APIConstants.API_VEHICLE_BY_REGISTRATION, null, pathParams, true, APIConstants.version.V3);
  if (actionResponse.status === ResponseCode.SUCCESS) {
    GetLatestPolicyInfo();
  }
  return actionResponse;
};

export const GetMotInfo = async (registration) => {
  const pathParams = {
    registration,
  };
  return get(APIConstants.API_MOT_INFO, null, pathParams);
};

export const getVehicleMotHistory = async (registration, session) => {
  const pathParams = {
    registration,
  };

  const headers = new Headers();
  headers.append('Authorization', `Bearer ${session.jwtToken}`);

  return get(APIConstants.API_MOT_HISTORY, headers, pathParams, false);
};

export const FindVehicle = async (payload) => post(APIConstants.API_FIND_VEHICLES, null, null, payload);

export const ConfirmRenewalPolicyVehiclesCorrect = async () => {
  logger.debug('confirming that policy vehicles are correct');

  return put(APIConstants.API_CONFIRM_RENEWAL_POLICY_VEHICLES_CORRECT, null, null, null, false, APIConstants.version.V3);
};
