import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import trackEvent from 'services/AnalyticsService';
import { ClaimOrangePointsReward } from 'services/MyRacService';
import styles from './Options.module.scss';

const Option = ({
  code,
  title,
  subTitle,
  description,
  chosenOption,
  isExtra,
  setChosenOption,
  claimReward,
  loadingReward,
  analyticsTag,
}) => {
  const isChosen = code === chosenOption;
  let classList = styles.option;

  if (isExtra) {
    classList += ` ${styles.isExtra}`;
  }
  if (isChosen) {
    classList += ` ${styles.isChosen}`;
  }
  if (!isChosen && chosenOption !== null) {
    classList += ` ${styles.greyedOut}`;
  }

  return (
    <>
      <button
        type="button"
        onClick={() => {
          trackEvent(analyticsTag);
          setChosenOption(code);
        }}
        className={classList}
      >
        {isExtra && <span className={styles.extraLabel}>{isExtra}</span>}
        <h3>
          <span className={styles.title}>{title}</span>
          <span className={styles.subTitle}>{subTitle}</span>
        </h3>
        <p className={styles.description}>
          {description}
        </p>
      </button>
      {isChosen && (
        <div className={styles.spendPointsFloatingBar}>
          <p>
            By spending your points you&apos;re confirming you&apos;ve read the reward T&Cs,
            and the reward you&apos;ve selected is right for you.
          </p>
          <button disabled={!chosenOption || loadingReward ? 'disabled' : false} type="button" onClick={claimReward}>
            {loadingReward ? (
              <>
                <div className="spinner-border spinner-border-sm p-2 mx-2" role="status" />
                Loading...
              </>
            ) : 'Spend your points'}
          </button>
        </div>
      )}
    </>
  );
};

const Options = ({ smrDiscountOfferAmount, renewalDiscountOfferAmount, setResponse }) => {
  const [chosenOption, setChosenOption] = useState(null);
  const [loadingReward, setLoadingReward] = useState(false);
  const { memberNo } = useSelector((state) => state.session);
  const customerInfo = useSelector((state) => state.customer.custInfo);

  const claimReward = async () => {
    setLoadingReward(true);
    try {
      const memberChoseRenewalDiscount = chosenOption === 'renewalDiscountOffer';
      const response = await ClaimOrangePointsReward(memberChoseRenewalDiscount, customerInfo, memberNo);
      if (response?.payload && response?.payload.orangePointsOfferRedeemed) {
        window.globalDataLayer = { OrangePointsRewardClaimed: memberChoseRenewalDiscount ? 'renewal' : 'service' };
        setLoadingReward(false);
        setResponse({ ...response.payload, memberChoseRenewalDiscount, smrDiscountOfferAmount, renewalDiscountOfferAmount });
      }
    } catch (e) {
      setLoadingReward(false);
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          Exchange your points! Either...
        </div>
        <div className={styles.options}>
          <Option
            code="smrDiscountOffer"
            title={`Save £${smrDiscountOfferAmount}`}
            subTitle="on a service at home"
            description={`Points go further with RAC Mobile Mechanics. Get £${smrDiscountOfferAmount} off a full or interim service at home.\nCheck you're eligible below before selecting.`}
            isExtra="Extra saving"
            chosenOption={chosenOption}
            setChosenOption={setChosenOption}
            claimReward={claimReward}
            loadingReward={loadingReward}
            analyticsTag="MyRAC_OrangePoints_Service_Discount_Pressed"
          />
          <Option
            code="renewalDiscountOffer"
            title={`Save £${renewalDiscountOfferAmount}`}
            subTitle="on breakdown cover"
            description={`Get £${renewalDiscountOfferAmount} off your annual renewal price, spread across your next 12 payments.`}
            chosenOption={chosenOption}
            setChosenOption={setChosenOption}
            claimReward={claimReward}
            loadingReward={loadingReward}
            analyticsTag="MyRAC_OrangePoints_Renewal_Discount_Pressed"
          />
        </div>
      </div>
      <div className={styles.btnWrapper}>
        <p>
          By spending your points you&apos;re confirming you&apos;ve read the reward T&Cs, and the reward you&apos;ve selected is right for you.
        </p>
        <button disabled={!chosenOption || loadingReward ? 'disabled' : false} className={styles.spendPointsBtn} type="button" onClick={claimReward}>
          {loadingReward ? (
            <>
              <div className="spinner-border spinner-border-sm p-2 mx-2" role="status" />
              Loading...
            </>
          ) : 'Spend your points'}
        </button>
      </div>
    </>
  );
};

export default Options;
