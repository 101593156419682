import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as DefaultText from 'configuration/defaultText';
import trackEvent from 'services/AnalyticsService';
import { GetContent, ResponseCode } from 'services/ContentService';
import { GetSMRContent } from 'services/MyRacService';
import { filterComponents, filterBanners } from 'utils/ContentHelpers';
import { getCoverTypeFlags } from 'views/MyRac/MyCover/utils';
import { getMessage } from 'services/MessageService';
import { generalMessages } from 'constants/cms/messageConstants';
import { connect } from 'react-redux';
import Toast, { ToastType } from 'components/Toast/Toast';
import PageTitle from 'shared/components/PageTitle/PageTitle';
import * as SessionActions from 'actions/sessionActions';
import ReactHtmlParser from '@orrisroot/react-html-parser';
import RemindersCard from './RemindersCard';
import PostDatedChangeInfo from './PostDatedChangeInfo';
import styles from './VehicleRemindersPage.module.scss';
import { useApplicationState } from '../../../hooks/applicationState-Context';
import InformationMessage from '../../InformationMessage';
import VehicleRemindersCard from './VehicleRemindersCard';
import { getHelperTextByCover } from '../../../utils/vehicleHelpers';
import AddVehicle from '../AddVehicle';
import SetRemindersModel from '../SetRemindersModel';
import SetVehicleHistoryModel from '../SetVehicleHistoryModel';
import Button from '../../../shared/components/Button/Button';

const VehicleRemindersPage = ({ vehicles: { vehicleInfo }, session, policy }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const changeVehicleId = Number(searchParams.get('changeVehicleId'));
  const [pageModel, setPageModel] = useState();
  const [showVehicleHistoryModal, setShowVehicleHistoryModal] = useState(false);
  const [editVehicleReminder, setEditVehicleReminder] = useState(null);
  const [editVehicleId, setEditVehicleId] = useState(changeVehicleId || null);
  const [showVehicleModal, setShowVehicleModal] = useState(!!changeVehicleId);
  const [showReminderModal, setShowReminderModal] = useState(false);
  const [editVehicleHistory, setEditVehicleHistory] = useState(null);
  const [motHistory, setMotHistory] = useState(null);
  const [reminderSMRData, setReminderSMRData] = useState(null);
  const [vehicleRemindersCardLoading, setVehicleRemindersCardLoading] = useState([]); // Loading vehicle ID list. Used when deleting vehicles.
  const { appState, setPageLoading } = useApplicationState();
  const cover = getCoverTypeFlags(policy?.policyInfo);
  const [toastMessage, setToastMessage] = useState(null);

  const getPageModel = async () => {
    const pageData = await GetContent('loggedinpages', 'Vehicle Details Page', ['banner', 'componentitems']);

    if (pageData.responseCode === ResponseCode.ERROR) {
      return { error: DefaultText.APIError };
    }
    const upcomingRemindersPanel = filterComponents('vehicledetails_reminders', pageData.payload);
    const baseVehiclePanel = filterComponents('vehicledetails', pageData.payload);
    const addEditVehicleModal = filterComponents('vehicledetails_add_or_edit_vehicle_modal', pageData.payload);
    const remindersModal = filterComponents('vehicledetails_reminder_modal', pageData.payload);
    const pageBanner = filterBanners('pagebanner', pageData.payload);

    setPageModel({
      title: pageData.payload.pagetitle,
      banner: pageBanner ? {
        displayBanner: pageBanner.displaybanner,
        backgroundColor: pageBanner.backgroundcolor,
        headerText: pageBanner.headertext,
        htmlText: pageBanner.html,
        textColor: pageBanner.textcolor,
        tellMeMore: {
          altText: pageBanner.tellmemoreurl.alttext,
          analyticsTag: pageBanner.tellmemoreurl.analyticstag,
          url: pageBanner.tellmemoreurl.href,
          openInNewWindow: pageBanner.tellmemoreurl.openinnewwindow,
          title: pageBanner.tellmemoreurl.title,
        },
      } : null,
      upcomingReminders: {
        title: upcomingRemindersPanel.title,
        noRemindersListed: upcomingRemindersPanel.no_reminders_listed,
        noVehicleListed: upcomingRemindersPanel.no_vehicle_listed,
        expired: upcomingRemindersPanel.expired,
        expiresToday: upcomingRemindersPanel.expires_today,
        expiresTomorrow: upcomingRemindersPanel.expires_tomorrow,
        expiresFuture: upcomingRemindersPanel.expires_in_period,
      },
      tacticalGarage: {
        displayImage: baseVehiclePanel.tactical_garage.display_image,
        garageDesktopImage: baseVehiclePanel.tactical_garage.garage_desktop_image,
        garageMobileImage: baseVehiclePanel.tactical_garage.garage_mobile_image,
        garageUrl: baseVehiclePanel.tactical_garage.garage_url,
      },
      vehiclesPanel: {
        title: baseVehiclePanel.title,
        addVehicleButtonText: baseVehiclePanel.add_vehicle_button_text,
        sectionTitles: {
          lapsedOrCancelled: baseVehiclePanel.section_titles.lapsed_or_cancelled,
          membershipOnly: baseVehiclePanel.section_titles.membership_only,
          payAsYouGo: baseVehiclePanel.section_titles.pay_as_you_go,
          personalBased: baseVehiclePanel.section_titles.personal_based,
          notCovered: baseVehiclePanel.section_titles.not_covered,
          vehicleBased: baseVehiclePanel.section_titles.vehicle_based,
          hybrid: baseVehiclePanel.section_titles.hybrid,
        },
        vehiclePanel: {
          title: baseVehiclePanel.vehicle_panel.vehicle_title,
          editVehicleButtonText: baseVehiclePanel.vehicle_panel.edit_vehicle_button_text,
          cardMessages: {
            vehicleBased: baseVehiclePanel.vehicle_panel.vehicle_card_messages.vehicle_based,
            personalBased: baseVehiclePanel.vehicle_panel.vehicle_card_messages.personal_based,
            payAsYouGo: baseVehiclePanel.vehicle_panel.vehicle_card_messages.pay_as_you_go,
            lapsedOrCancelled: baseVehiclePanel.vehicle_panel.vehicle_card_messages.lapsed_or_cancelled,
            notCovered: baseVehiclePanel.vehicle_panel.vehicle_card_messages.not_covered,
          },
        },
        remindersPanel: {
          title: baseVehiclePanel.reminder_panel.reminder_title,
          text: baseVehiclePanel.reminder_panel.descriptive,
          noticeMessage: baseVehiclePanel.reminder_panel.no_reminders_message,
          helperText: baseVehiclePanel.reminder_panel.helper_text,
          noReminderText: baseVehiclePanel.reminder_panel.reminder_not_set,
        },
        addEditVehicleModal: {
          addVehicleButtonText: addEditVehicleModal.add_manually_button_text,
          responseScreens: {
            successTitle: addEditVehicleModal.update_response_screen_titles.success_title,
            successDetail: addEditVehicleModal.update_response_screen_titles.success_detail,
            errorTitle: addEditVehicleModal.update_response_screen_titles.error_title,
          },
          screenTitles: {
            addTitle: addEditVehicleModal.screen_titles.add_vehicle_title,
            editTitle: addEditVehicleModal.screen_titles.edit_vehicle_title,
            deleteTitle: addEditVehicleModal.screen_titles.delete_title,
          },
          deleteText: addEditVehicleModal.delete_descriptive_text,
          enterDetailsManuallyButtonText: addEditVehicleModal.add_manually_button_text,
          cancelButtonText: getMessage(generalMessages.CANCEL_BUTTON_TEXT),
          findVehicleButtonText: addEditVehicleModal.find_vehicle_button_text,
          searchVehicle: {
            spinnerText: addEditVehicleModal.search_spinner_text,
          },
          deleteVehicleButtonText: addEditVehicleModal.delete_vehicle_button_text,
          editHelperText: {
            vehicleBased: addEditVehicleModal.edit_helper_text.vehicle_based,
            personalBased: addEditVehicleModal.edit_helper_text.personal_based,
            notCovered: addEditVehicleModal.edit_helper_text.not_covered,
            payAsYouGo: addEditVehicleModal.edit_helper_text.pay_as_you_go,
            notActive: addEditVehicleModal.edit_helper_text.not_active,
          },
          addHelperText: {
            notCovered: addEditVehicleModal.add_helper_text.not_covered,
            covered: addEditVehicleModal.add_helper_text.covered,
          },
          fieldValidation: {
            invalidReg: addEditVehicleModal.field_validations.search_invalid_registration_message,
            registration: {
              invalid: addEditVehicleModal.field_validations.registration_invalid_format,
              required: addEditVehicleModal.field_validations.registration_required_field,
              dupe: addEditVehicleModal.field_validations.registration_dupe || 'You’ve already added this vehicle. Please enter a different vehicle or cancel.',
            },
            make: {
              invalid: addEditVehicleModal.field_validations.make_invalid_format,
              required: addEditVehicleModal.field_validations.make_required_field,
            },
            model: {
              invalid: addEditVehicleModal.field_validations.model_invalid_format,
              required: addEditVehicleModal.field_validations.model_required_field,
            },
            year: {
              invalid: addEditVehicleModal.field_validations.year_invalid_format,
              required: addEditVehicleModal.field_validations.year_required_field,
            },
          },
          notAbleToEdit: addEditVehicleModal.edit_not_allowed,
        },
        reminderModal: {
          saveErrorText: remindersModal.save_error_text,
          motDetailsFailure: remindersModal.mot_prepop_failure_message,
          title: remindersModal.title,
          text: remindersModal.descriptive,
          successTitle: remindersModal.update_success_title,
          successDetail: remindersModal.update_success_detail,
          errorTitle: remindersModal.update_error_title,
        },
        historyModal: {
          errorText: remindersModal.history_model_error_text,
          noMotErrorText: remindersModal.history_no_mot_error_text,
        },
        historyPanel: {
          noticeMessage: remindersModal.history_notice_message,
          text: remindersModal.history_panel_text,
          sourceMessage: remindersModal.history_source_message,
        },
      },
    });
    return null;
  };

  const motHistoryButtonClick = (vehicle, vehicleMotHistory) => {
    setEditVehicleHistory(vehicle);
    setMotHistory(vehicleMotHistory);
  };

  const getSMRData = async () => {
    const response = await GetSMRContent();
    if (response.payload) {
      setReminderSMRData(response.payload);
    }
  };

  const displayVehicles = (vehicles) => vehicles.map((vehicle) => (
    <VehicleRemindersCard
      key={vehicle.vehicleId}
      vehicle={vehicle}
      coverStatus={vehicle.coverState}
      coverText={ReactHtmlParser(getHelperTextByCover(vehicle.coverState, pageModel.vehiclesPanel.sectionTitles))}
      remindersList={vehicle.reminders}
      vehicleEditOnClick={() => { setEditVehicleId(vehicle.vehicleId); setShowVehicleModal(!showVehicleModal); setToastMessage(null); }}
      reminderEditOnClick={() => { setShowReminderModal(true); setEditVehicleReminder(vehicle); setToastMessage(null); }}
      motHistoryButtonClick={motHistoryButtonClick}
      setShowVehicleHistoryModal={setShowVehicleHistoryModal}
      vehicleRemindersCardLoading={!!(vehicleRemindersCardLoading.includes(vehicle.vehicleId))}
      reminderSMRData={reminderSMRData}
      policyVehicleBreakdownExtras={vehicleInfo.policyVehicleBreakdownExtras}
    />
  ));

  useEffect(async () => {
    if (!pageModel) {
      trackEvent('Page Change - vehicle-details');
      await getPageModel().then(() => { setPageLoading(false); });
    }
    if (pageModel && searchParams.get('changeVehicleId')) {
      searchParams.delete('changeVehicleId');
      setSearchParams(searchParams);
    }
  }, [pageModel]);

  useEffect(async () => {
    await getSMRData();
  }, []);

  if (vehicleInfo.hasError) {
    return <InformationMessage text={DefaultText.APIError} />;
  }

  const reminderVehiclesLength = vehicleInfo && vehicleInfo.reminderVehicles ? vehicleInfo.reminderVehicles.length : false;

  const vehiclesHeader = (title, subTitle) => (
    <>
      <h4 className={styles.title}>{title}</h4>
      <p className={styles.subTitle}>{subTitle}</p>
    </>
  );

  const vehicleSections = () => {
    if (cover.isVbm && vehicleInfo && reminderVehiclesLength) {
      const policyVehicles = vehicleInfo.reminderVehicles.filter((item) => item.isPolicyVehicle);
      const nonPolicyVehicles = vehicleInfo.reminderVehicles.filter((item) => !item.isPolicyVehicle);

      return (
        <>
          {policyVehicles.length > 0 && vehiclesHeader('Vehicles covered', 'These are covered if you break down')}
          {vehicleInfo.hasPostDatedRenewalWithVehicleEdit && <PostDatedChangeInfo />}
          {policyVehicles.length > 0 && displayVehicles(policyVehicles)}
          {nonPolicyVehicles.length > 0 && vehiclesHeader('Other vehicles', 'These aren\'t covered if you break down')}
          {nonPolicyVehicles.length > 0 && displayVehicles(nonPolicyVehicles)}
        </>
      );
    }
    if (vehicleInfo && reminderVehiclesLength) {
      return (
        <>
          {vehicleInfo.hasPostDatedRenewalWithVehicleEdit && <PostDatedChangeInfo />}
          {displayVehicles(vehicleInfo.reminderVehicles)}
        </>
      );
    }
    return <p className={styles.noVehiclesText}>Add a vehicle to get started setting reminders for your MOT, road tax, vehicle insurance and annual service - all in one place. We&#39;ll let you know what&#39;s coming up so you never miss a date.</p>;
  };

  return (
    !appState.loading.status && (
      <div className={styles.container}>
        <PageTitle text="My vehicles" subheader="Manage your vehicles even if they&apos;re not on your policy." greyColor />
        {!showVehicleModal && !showReminderModal && toastMessage && <Toast msg={toastMessage.msg} type={toastMessage.type} closeHandler={() => setToastMessage(null)} />}
        <div className={styles.vehiclesReminderContainer}>
          <div className={styles.vehiclesContainer}>
            {vehicleSections()}
            {vehicleInfo.canAddVehicles && (
              <div className={styles.addVehicleBtnContainer}>
                <Button
                  buttonText="Add a vehicle"
                  chevronWhite
                  onClickHandler={() => { setShowVehicleModal(!showVehicleModal); }}
                />
              </div>
            )}
          </div>
          <div className={`${styles.remindersContainer} ${cover.isVbm ? styles.vbmAdjustedPadding : ''}`}>
            <RemindersCard
              pageModel={pageModel.tacticalGarage}
              upcomingRemindersList={vehicleInfo.upcomingReminders}
              reminderVehicles={vehicleInfo.reminderVehicles}
              smallMessage={pageModel?.vehiclesPanel?.historyPanel?.sourceMessage}
              reminderSMRData={reminderSMRData}
            />
          </div>
        </div>
        {
          showVehicleModal && (
            <AddVehicle
              action={() => { setShowVehicleModal(!showVehicleModal); setEditVehicleId(null); }}
              forceQuit={() => { setShowVehicleModal(!showVehicleModal); setEditVehicleId(null); }}
              pageModel={pageModel.vehiclesPanel.addEditVehicleModal}
              vehicleId={editVehicleId}
              isInEditMode={!!editVehicleId}
              addedVehicleCallback={(vehicle, edited = false) => {
                setEditVehicleReminder(vehicle);
                setShowReminderModal(true);
                setToastMessage(edited && vehicle.isPolicyVehicle ? {
                  msg: 'Thanks for updating your vehicle',
                  type: ToastType.SUCCESS,
                } : null);
              }}
              vehicleRemindersCardLoading={vehicleRemindersCardLoading}
              setVehicleRemindersCardLoading={setVehicleRemindersCardLoading}
            />
          )
        }

        {
          showReminderModal && (
            <SetRemindersModel
              data={editVehicleReminder}
              action={() => { setShowReminderModal(!showReminderModal); }}
              session={session}
              pageModel={pageModel.vehiclesPanel.reminderModal}
            />
          )
        }

        {showVehicleHistoryModal && (
          <SetVehicleHistoryModel
            data={editVehicleHistory}
            pageModel={pageModel.vehiclesPanel.historyModal}
            action={() => { setShowVehicleHistoryModal(!showVehicleHistoryModal); }}
            session={session}
            motHistory={motHistory}
            isLoading={false}
          />
        )}
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  vehicles: state.vehicles,
  session: state.session,
  policy: state.policy,
});

const mapDispatchToProps = (dispatch) => ({
  updateCovidBannerClosed: (bannerHidden) => {
    dispatch(SessionActions.setBannerHidden({ covidBanner: bannerHidden }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleRemindersPage);
